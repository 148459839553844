<template>
  <u-button variant="ghost" :size="size" :color="dark ? 'white' : 'charcoal'" :ui="ui">
    <slot />
  </u-button>
</template>

<script setup>

  const props = defineProps({
    size: {
      type: String,
      default: 'md'
    },
    dark: Boolean
  });

  const ui = computed(() => {
    if (props.dark) {
      return {
        base: 'flex-col flex-center',
        color: {
          white: {
            ghost: 'text-white hover:bg-white/15'
          }
        }
      }
    }

    return {
      base: 'flex-col flex-center'
    };
  })

</script>
